import { useContext } from 'react'
import { useSession } from 'utils/session'
import { useProfile } from 'utils/profile'
import { useDatadog } from 'utils/datadog'
import { useTrackPage, useTrackEvent } from 'utils/analytics'
import { Tealium } from 'components/Tealium'
import { AnalyticsContext } from 'providers/AnalyticsProvider'

function Analytics({ children }) {
  const { state: { publicProcessDotEnv, referer, host } = {} } = useContext(AnalyticsContext)

  const {
    NEXT_PUBLIC_ANALYTICS_ENV,
    NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    NEXT_PUBLIC_DATADOG_ENV,
    NEXT_PUBLIC_TEALIUM_UTAG_URL,
  } = publicProcessDotEnv

  useSession()

  useProfile()

  useDatadog(
    NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    NEXT_PUBLIC_DATADOG_ENV
  )

  useTrackEvent()

  useTrackPage({ analyticsEnv: NEXT_PUBLIC_ANALYTICS_ENV, referer, host })

  return (
    <>
      <Tealium url={NEXT_PUBLIC_TEALIUM_UTAG_URL} />
      {children}
    </>
  )
}

export { Analytics }
