import Script from 'next/script'
import { addTealiumScript } from 'components/Tealium/addTealiumScript'

function Tealium({ url }) {
  return (
    <Script
      id="tealium-base"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: addTealiumScript(url),
      }}
    />
  )
}

export { Tealium }
