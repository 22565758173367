import { useEffect, useContext } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { ANALYTICS } from 'constants/analytics'

const useDatadog = (applicationId, clientToken, env) => {
  const { state: { achieveProfileId, achieveSessionId } = {} } = useContext(AnalyticsContext)

  useEffect(() => {
    if (!applicationId || !clientToken || !env) return
    datadogRum.init({
      applicationId,
      clientToken,
      site: ANALYTICS.DATADOG_SITE,
      service: ANALYTICS.DATADOG_SERVICE,
      env,
      // Specify a version number to identify the deployed version of your application in Datadog
      // We will get this from build number pipeline, since it's inlined at build, we can access it via process.env
      version: process.env.NEXT_PUBLIC_VERSION ? process.env.NEXT_PUBLIC_VERSION : '0.0.0',
      sampleRate: 100,
      premiumSampleRate: 50,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    datadogRum.startSessionReplayRecording()
    return () => {
      datadogRum.stopSessionReplayRecording()
    }
  }, [applicationId, clientToken, env])

  useEffect(() => {
    datadogRum.setUser({
      achieve_session_id: achieveSessionId,
      achieve_profile_id: achieveProfileId,
    })
    return () => {
      datadogRum.removeUser()
    }
  }, [achieveProfileId, achieveSessionId])
}

export { useDatadog }
