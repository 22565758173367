import styles from './Background.module.css'
import Section from 'components/Section'

export function Background({ children, imageContent }) {
  const url = imageContent?.fields.file.url
  const localStyling = {
    backgroundImage: `url(https:${url})`,
  }

  return (
    <Section style={url ? localStyling : {}} className={styles['background-section']}>
      {children}
    </Section>
  )
}
