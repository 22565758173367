import { useEffect, useContext } from 'react'
import { profile } from 'services/profile'
import { ANALYTICS } from 'constants/analytics'
import Cookies from 'js-cookie'
import { AnalyticsContext } from 'providers/AnalyticsProvider'

const fetchProfileCookie = async ({ dispatch }) => {
  const profileId = Cookies.get(ANALYTICS.HUB_PROFILE_ID_COOKIE_NAME)
  if (profileId) return
  const res = await fetch('/api/profile/id', {
    method: 'POST',
  })
  const { id } = await res.json()
  Cookies.set(ANALYTICS.HUB_PROFILE_ID_COOKIE_NAME, id, { expires: 36525 })
  dispatch({
    type: 'SET_ACHIEVE_PROFILE_ID',
    id: Cookies.get(ANALYTICS.HUB_PROFILE_ID_COOKIE_NAME),
  })
}

const useProfile = () => {
  const { dispatch } = useContext(AnalyticsContext)
  return useEffect(() => {
    fetchProfileCookie({ dispatch })
  }, [dispatch])
}

const getProfileId = async () => {
  try {
    const { id } = await profile.post()
    if (!id) {
      throw new Error('Missing profile id')
    }
    return id
  } catch (error) {
    console.error('Unable to set profile cookie', error)
  }
}

export { getProfileId, useProfile }
