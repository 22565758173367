import { createContext, useReducer } from 'react'
import Cookies from 'js-cookie'
import { ANALYTICS } from 'constants/analytics'
import { Analytics } from 'components/Analytics'

const AnalyticsContext = createContext()

const analyticsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACHIEVE_PROFILE_ID':
      return {
        ...state,
        achieveProfileId: action.id,
      }
    case 'SET_ACHIEVE_SESSION_ID':
      return {
        ...state,
        achieveSessionId: action.id,
      }
    case 'SET_TRACKING_DATA':
      return {
        ...state,
        trackingData: action.payload.trackingData,
      }
    case 'CLEAR_TRACKING_DATA':
      return {
        ...state,
        trackingData: state.trackingData,
      }
    case 'SET_PAGE_STATE_LOADING':
      return {
        ...state,
        isLoaded: action.payload.isLoaded,
      }
    case 'SET_PAGE_STATE_LOADED':
      return {
        ...state,
        trackingData: action.payload.trackingData,
        isLoaded: action.payload.isLoaded,
      }
    case 'ADD_EVENT_TO_QUEUE':
      return {
        ...state,
        eventQueue: state.eventQueue.concat(action.payload.event),
      }
    case 'REMOVE_LAST_EVENT_FROM_QUEUE':
      return {
        ...state,
        eventQueue: state.eventQueue.filter((_, i) => i !== state.eventQueue.length - 1),
      }
    case 'CLEAR_EVENT_QUEUE':
      return {
        ...state,
        eventQueue: state.eventQueue,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const AnalyticsProvider = ({ children, publicProcessDotEnv, referer, host }) => {
  const [state, dispatch] = useReducer(analyticsReducer, {
    achieveProfileId: Cookies.get(ANALYTICS.HUB_PROFILE_ID_COOKIE_NAME),
    achieveSessionId: Cookies.get(ANALYTICS.ACHIEVE_SESSION_KEY_NAME),
    publicProcessDotEnv,
    referer,
    host,
    isLoaded: false,
    eventQueue: [],
    trackingData: null,
  })
  return (
    <AnalyticsContext.Provider value={{ state, dispatch }}>
      <Analytics>{children}</Analytics>
    </AnalyticsContext.Provider>
  )
}

export { AnalyticsProvider, AnalyticsContext }
