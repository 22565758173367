const addTealiumScript = (TEALIUM_UTAG_URL) => {
  // This script is copied directly from the Tealium docs.
  return `
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}
    window.utag_cfg_ovrd.noview = true
    ;(function (a, b, c, d) {
      a = '${TEALIUM_UTAG_URL}'
      b = document
      c = 'script'
      d = b.createElement(c)
      d.src = a
      d.type = 'text/java' + c
      d.async = true
      a = b.getElementsByTagName(c)[0]
      a.parentNode.insertBefore(d, a)
    })()
  `
}

export { addTealiumScript }
