// Hub Profile Service
// https://platform-api.dev.ffngcp.com/docs?f=%2Fswagger%2Fffn%2Fconsumer%2Fprofile%2Fv1%2Fprofile_service.swagger.json
import { ffnAuth } from 'services/ffnAuth'

const SERVICE = process.env.HUB_PROFILE_SERVICE

/**
 * Create a profile
 * @param {Object} reqBody
 * @returns Profile
 */
const post = async ({ reqBody, query } = {}) => {
  let url = `${SERVICE}`
  const init = {
    method: 'POST',
    headers: {},
  }
  if (reqBody) {
    init.body = JSON.stringify(reqBody)
    init.headers['Content-Type'] = 'application/json'
  }
  if (query) {
    const param = new URLSearchParams(query)
    url = `${url}?${param.toString()}`
  }
  const response = await ffnAuth.fetch(url, init)
  const { profile = {} } = await response.json()
  return profile
}

/**
 * Get a profile by id
 * @param {String} id Profile ID
 * @returns Profile
 */
const get = async (id) => {
  if (!id) {
    throw new Error('Missing profile id')
  }
  const init = {
    method: 'GET',
    headers: {},
  }
  const response = await ffnAuth.fetch(`${SERVICE}/${id}`, init)
  const { profile = {} } = await response.json()
  return profile
}

/**
 * Update a profile
 * @param {String} id Profile ID
 * @param {Object} reqBody Request body
 * @param {Object} query { update_mask: 'string' }
 * @returns Profile
 */
const patch = async ({ id, reqBody, query } = {}) => {
  if (!id) {
    throw new Error('Missing profile id')
  }
  if (!reqBody) {
    throw new Error('Missing profile reqBody')
  }
  let url = `${SERVICE}/${id}`
  const init = {
    method: 'PATCH',
    headers: {},
  }
  if (reqBody) {
    init.body = JSON.stringify(reqBody)
    init.headers['Content-Type'] = 'application/json'
  }
  if (query) {
    const param = new URLSearchParams(query)
    url = `${url}?${param.toString()}`
  }
  const response = await ffnAuth.fetch(url, init)
  const { profile = {} } = await response.json()
  return profile
}

const profile = {
  post,
  get,
  patch,
}

export { profile }
